$c_primary: #0f0f0f;
$c_secondary: rgb(204, 193, 35);

$c_primary_hover: #A68A32;
$c_primary_text: black;
$c_form_border: #dde68d;
$c_white: #fff;
$c_black: #000;
$c_red: #ff0000;
$c_red_hover: #c20303;
$c_text_grey: #999;
$shadow: rgba(0, 0, 0, 0.08) 0px 2px 25px 0px;
$shadow2: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
$transition: all 0.3s ease-in-out 0s;



// body{
//   background-image: url('/static/images/2292787.jpg');
// }

// Common
.anchor_link {
  color: $c_primary;
  transition: $transition;
  cursor: pointer;

  &:hover {
    color: $c_primary_hover;
  }
}

// Button
.MuiButton-root {
  &.btn {
    height: 50px;
    padding: 5px 20px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.3px;
    box-shadow: none;
    text-transform: capitalize;
    gap: 5px;

    &:hover {
      box-shadow: none;
    }

    svg {
      font-size: 20px;
    }
  }

  &.btn_primary {
    color: $c_secondary;
    background-color: $c_primary;

    &:hover {
      background-color: $c_primary_hover;
      color: $c_white;
    }
  }

  &.btn_danger {
    color: $c_white;
    background-color: $c_red;

    &:hover {
      background-color: $c_red_hover;
    }
  }

  &.sm {
    height: 35px;
    padding: 5px 10px;
  }
}

.loader_loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  left: 0%;

  figure {
    width: 60px;
    height: 60px;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}

.searchWrapper {
  border: 1px solid rgb(204, 193, 35);
  border-radius: 4px;
  height: 50px;
  padding: 10px;
  position: relative;
}

.users_autocomplete {
  .MuiInputBase-formControl.MuiInputBase-adornedEnd {
    // padding: 0 !important;
    height: 50px !important;
    overflow: hidden;
  }

  input {
    border: 1px solid transparent !important;
    height: 0 !important;
    padding: 16px;
  }
}

.pagination {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    width: 100%;
    min-height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "regular";
    font-size: 18px;
  }

  p {
    font-size: 14px;
    font-family: "regular";

    span {
      font-family: "bold";
      color: black;
    }
  }

  .pages {
    display: flex;

    .prevbtn {
      background: no-repeat;
      border: none;
    }

    svg {
      font-size: 12px;
      border: 0.5px solid #a5a5a5;
      padding: 6px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      margin-right: 10px;
      cursor: pointer;
      color: black;
    }

    .count {
      display: flex;
      align-items: center;
      margin-right: 10px;
      background: none;
      /* padding: 6px; */
      border-radius: 24px;
      border: 0.5px solid #fff;

      .actv {
        background: $c_secondary;
        color: white;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        cursor: pointer;
      }

      .inActv {
        background: transparent;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        color: black;
        cursor: pointer;
        border: none;
      }
    }
  }
}


.search_bar {
  width: 260px;
  position: relative;

  .search_icon {
    position: absolute;
    width: auto;
    height: auto;
    margin: 0;
    max-height: initial;
    color: #999;
    left: 10px;
  }

  input {
    padding-left: 40px;
  }
}

.flexdiv {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  gap: 20px;

  button {
    background-color: $c_secondary;
    border: 1px solid $c_secondary;
    color: $c_white;

    &:hover {
      background-color: $c_secondary;
      color: $c_white;
      border: 1px solid $c_secondary;
    }
  }
}


.text_field textarea {
  color: black;
  font-weight: 500;
  height: 50px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 5px !important;
  border: 1px solid $c_form_border;
  box-shadow: none !important;
  background-color: #fff;
  padding: 10px;
}

.text_field p,
.err_msg {
  color: red !important;
  font-size: 14px !important;
  margin: 0 !important;
  font-weight: 400;
}


// Forms
.custom_label[class*="MuiTypography"] {
  font-size: 14px;
  font-weight: 600;
  color: $c_primary_text;
  margin-bottom: 5px;
  letter-spacing: 0.3px;
  line-height: 1.2;
}

.MuiFormControl-root,
.MuiTextField-root,
.react-tel-input {
  >.MuiInputBase {
    &-root {
      position: relative;
      padding: 0;

      &::before,
      &::after {
        display: none;
      }
    }

    &-hiddenLabel {
      >fieldset {
        display: none;
      }
    }
  }

  .react-tel-input .form-control {
    width: 100% !important;
  }

  :is(input, select, input.form-control) {
    color: $c_black;
    font-weight: 500;
    height: 50px;
    box-sizing: border-box;
    font-size: 14px;
    border-radius: 5px !important;
    border: 1px solid $c_form_border;
    box-shadow: none !important;

    &:focus {
      border-color: $c_primary_text;
    }

    &::placeholder {
      color: $c_black;
      opacity: 0.6;
    }
  }

  :is(input, select) {
    padding: 5px 15px;
  }

  input:has(+ .eye_btn) {
    padding-right: 50px;
  }

  .eye_btn {
    position: absolute;
    height: 50px;
    max-height: 50px;
    width: 50px;
    margin: 0;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      height: 80%;
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0 auto;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.MuiFormControlLabel-root {
  color: $c_black;
  line-height: 1.2;
  margin: 0 0 0 -3px !important;
  gap: 4px;

  .MuiCheckbox-root {
    padding: 0;
    color: $c_form_border;
    position: relative;
    top: -1px;

    &.Mui-checked {
      color: $c_primary;
    }
  }

  svg {
    width: 25px;
    height: 25px;
  }

  .MuiFormControlLabel-label {
    font-size: 14px;
    font-weight: 600;
    color: $c_primary_text;
    display: inline-flex;
  }
}

.form_btn {
  margin-top: 30px;
}

// Auth
.auth_module {
  min-height: 100vh;
  background-image: linear-gradient(180deg, $c_secondary, $c_primary);
  background-attachment: fixed;

  .auth_logo {
    margin-bottom: 30px;

    .logo_cntnr {
      height: 100px;
      margin: 0;

      img {
        height: 100%;
      }
    }
  }

  .auth_cntnr {
    max-width: 480px;
    border-radius: 0;
    background-color: transparent;
    padding: 0;
  }

  .auth_box {
    max-width: 100%;
    border-radius: 30px;
    background-color: $c_white;
    padding: 40px 40px;

    h5 {
      font-size: 20px;
      font-weight: 800;
    }
  }

  .anchor_link,
  .anchor_link p {
    font-size: 14px;
    font-weight: 600;
    display: inline-flex;
  }

  .opt_fields {
    max-width: 300px;
    margin-inline: auto;
    display: flex;
  }
}

// Sidebar
.sidebar-main-div {
  .sidebar_drawer {
    .MuiDrawer-paper {
      background-color: $c_primary;
    }
  }

  .sidebar-logo {
    padding: 20px 20px;
    position: sticky;
    top: 0;
    left: 0;
    background-color: $c_primary;
    z-index: 9;
    text-align: center;

    figure {
      width: 100%;
      height: 100px;
      margin: 0;

      img {
        max-height: 100%;
        max-width: 100%;
        cursor: pointer;
      }
    }
  }

  .sidebr-lst {
    padding: 0 0 20px;

    .lst-itm {
      padding: 5px 20px;
      min-height: 55px;
      gap: 10px;
      transition: $transition;

      &:hover {
        background-color: rgba($c_secondary, 0.2);
      }

      &:not(:last-child) {
        // margin-bottom: 5px;
      }

      .MuiListItemIcon-root {
        height: 35px;
        min-width: 35px;
        color: $c_white;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: $c_secondary;

        svg {
          height: 20px;
          width: 20px;
        }
      }

      .lstitm-txt {
        margin: 0;
        color: $c_secondary;
        display: inline-flex;
        transition: $transition;

        span {
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0.3px;
          line-height: 1.2;
          transition: $transition;
        }
      }

      &.Mui-selected {
        border-color: $c_secondary;
        background-color: rgba($c_secondary, 0.6);

        .lstitm-txt {
          color: $c_white;

          span {
            font-weight: 600;
          }
        }
      }
    }
  }
}

// Topbar
.top_bar {
  .topbar {
    box-shadow: $shadow;
    background-color: $c_white;

    .MuiToolbar-root {
      min-height: 70px;
      padding: 10px 20px;
      box-sizing: border-box;
    }
  }

  .topbar_user_btn {
    display: flex;
    align-items: center;
    padding: 5px 5px 5px 8px;
    border-radius: 5px;
    background-color: #f9f9f9;

    img {
      height: 40px;
      width: 40px;
      min-width: 40px;
      border-radius: 50%;
      margin-right: 8px;
    }

    span {
      font-size: 14px;
      font-weight: 600;
      color: #555;
    }

    svg {
      height: 20px;
      width: 20px;
      margin-left: 3px;
    }
  }

  .notification_btn,
  .chat_btn {
    height: 50px;
    width: 50px;
    border-radius: 5px;
    background-color: #f9f9f9;

    &:hover {
      color: $c_primary;
    }
  }

  .chat_btn {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      right: 10px;
      top: 10px;
      width: 7px;
      height: 7px;
      background-color: $c_secondary;
      border-radius: 50%;
      display: inline-flex;
      opacity: 0;
    }

    &.active {
      &::before {
        opacity: 1;
      }
    }
  }
}

.userDropdown_menu {
  .userDropdown_menu_list {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    min-width: 120px;

    button {
      padding: 0;
      pointer-events: none;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.notiDropdown_menu {
  .MuiMenu-paper {
    max-width: 450px;
    overflow-y: auto;
  }

  ul.MuiList-padding {
    padding: 0;
  }

  .noti_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding: 10px 15px;
    position: sticky;
    width:300px;
    top: 0;
    left: 0;
    background-color: $c_white;
    z-index: 2;

    h2 {
      font-size: 16px;
      font-weight: 700;
      margin: 0;
      color: $c_black;
    }

    .btn {
      height: 30px;
      font-size: 12px;
      font-weight: 600;
    }
  }

  .noti_list {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: flex;
      align-items: flex-start;
      padding: 10px 15px;
      gap: 10px;
      cursor: pointer;
      transition: $transition;

      &:hover {
        background-color: rgba(32, 78, 51, 0.1);
      }

      &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }

      figure {
        height: 40px;
        width: 40px;
        min-width: 40px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      h3 {
        font-size: 14px;
        font-weight: 700;
        margin: 0 0 2px;
        color: $c_black;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 15px;

        span {
          font-size: 12px;
          font-weight: 500;
          margin: 0;
          color: $c_text_grey;
        }
      }

      p {
        font-size: 12px;
        font-weight: 500;
        margin: 0;
        color: #555;
      }
    }
  }
}

// Main
.dashboard_main {
  .sub-layout {
    padding: calc(70px + 25px) 20px 25px;
    max-width: 100% !important;
  }

  .cards {
    padding: 20px 20px;
    box-shadow: $shadow2;
    border-radius: 10px;
    color: $c_black;
    overflow: initial;

    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;

      &_right {
        display: flex;
        align-items: center;
        gap: 15px;
      }
    }
  }
}

.main_loyout {
  .dashboard {
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;

    h1 {
      font-size: 20px;
      font-weight: 800;
      letter-spacing: 0.5px;
      line-height: 1.3;
      margin: 0;
    }
  }
}

.dashGrid {
  .dashGrid_item {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    text-align: left;
    gap: 20px;
    border-bottom: 2px solid $c_primary;
    cursor: pointer;
    transition: $transition;

    &:hover {
      transform: translateY(-5px);
    }

    .svg_icn {
      height: 45px;
      width: 45px;
      min-width: 45px;
      padding: 10px;
      color: $c_white;
      background: $c_secondary;
      border-radius: 5px;
      box-sizing: border-box;
    }

    h3 {
      font-size: 14px;
      color: $c_primary_text;
      font-weight: 600;
      letter-spacing: 0.3px;
      margin: 0;
      line-height: 1;
    }

    h4 {
      font-size: 25px;
      color: $c_black;
      padding-top: 10px;
      margin: 0;
      line-height: 1;
      font-family: "Montserrat", sans-serif !important;
    }
  }
}

.dashGraph {
  .dashGraph_item {
    text-align: left;

    h2 {
      font-size: 18px;
      font-weight: 800;
      letter-spacing: 0.5px;
      line-height: 1.3;
      margin: 0 0 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      color: $c_black;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.search_bar {
  width: 260px;
  position: relative;

  .search_icon {
    position: absolute;
    width: auto;
    height: auto;
    margin: 0;
    max-height: initial;
    color: #999;
    left: 10px;
  }

  input {
    padding-left: 40px;
  }
}

.table_container {
  margin-top: 20px;

  table {
    width: 100%;

    tr {
      :is(th, td) {
        font-size: 14px;
        font-weight: 500;
        color: $c_black;
        line-height: 1.2;
        letter-spacing: 0;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 10px 15px;
        white-space: nowrap;
        box-sizing: border-box;
      }

      th {
        font-weight: 700;
        background-color: rgb(32 78 51 / 20%);
        color: $c_primary_text;

        &:first-child {
          &:has(input[type="checkbox"]) {
            width: 60px;
            min-width: 60px;
          }
        }

        &:nth-child(2):contains("S.No") {
          width: 70px;
          min-width: 70px;
        }

        &:first-child,
        &:nth-child(2) {
          &:contains("S.No") {
            width: 70px;
            min-width: 70px;
          }
        }
      }
    }

    .user_img {
      margin: 0;

      img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }
    }

    .bnr_img {
      margin: 0;

      img {
        width: 70px;
        height: 55px;
        border-radius: 4px;
        object-fit: cover;
        object-position: center;
      }
    }

    .table_actions {
      display: inline-flex;
      align-items: center;
      gap: 5px;

      button {
        height: 35px;
        width: 35px;
        min-width: 35px;
        padding: 0;
        border-radius: 50%;
        background-color: #f5f5f5;
        color: black;
        transition: $transition;

        svg {
          font-size: 18px;
        }

        &:hover {
          color: $c_white;
          background-color: $c_primary;
        }
      }
    }

    .MuiCheckbox-root {
      padding: 0;
      color: $c_primary;
    }
  }

  .MuiFormControl-root {
    select {
      height: 35px;
      padding: 2px 20px 2px 10px !important;

      &+svg {
        font-size: 20px;
        right: 0;
      }
    }
  }
}

.upload_image {
  label {
    display: inline-flex;
  }

  &_btn[class*="btn"] {
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0;
    padding: 0;

    &:hover {
      background-color: transparent;
    }

    img {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      object-fit: cover;
    }

    svg {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      color: $c_white;
      background-color: $c_primary;
      padding: 6px;
      box-sizing: border-box;
      margin-top: -15px;
    }
  }

  &_preview {
    display: inline-flex;
    position: relative;
    align-items: center;
    flex-direction: column;

    img {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      object-fit: cover;
    }

    svg {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      color: $c_white;
      background-color: $c_red;
      padding: 6px;
      box-sizing: border-box;
      margin-top: -15px;
    }
  }
}

.view_box {
  .profile_img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    margin: 0;

    img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &_list {
    .MuiBox-root {
      &:not(:last-child) {
        margin-bottom: 20px;
      }

      h5 {
        color: $c_primary_text;
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 5px;
      }

      p {
        color: $c_black;
        font-size: 14px;
        font-weight: 600;
        margin: 0;
      }
    }
  }

  .detail_title {
    font-size: 16px;
    font-weight: 700;
  }
}

.custom_tabs {
  .custom_tabs_links {
    margin-bottom: 20px;

    .MuiTabs-flexContainer {
      display: inline-flex;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      padding: 10px;
      gap: 5px;

      +.MuiTabs-indicator {
        display: none;
      }

      button {
        min-height: 40px;
        padding: 0 15px;
        font-size: 14px;
        font-weight: 600;
        color: $c_primary_text;
        text-transform: capitalize;
        border-radius: 5px;
        line-height: normal;
        transition: $transition;

        &:hover {
          color: $c_black;
          background-color: rgb(32 78 51 / 10%);
        }

        &.Mui-selected {
          color: $c_white;
          background-color: $c_primary;
        }
      }
    }
  }

  [role="tabpanel"] {
    >.MuiBox-root {
      padding: 0;
    }
  }
}

.faq_box {
  max-width: 70%;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
  position: relative;

  .btn {
    position: absolute;
    left: calc(100% + 10px);
    top: 0;
  }
}

.revert_dialog {
  .MuiDialog-paper {
    max-width: 400px;
    width: 100%;

    .MuiDialogContent-root {
      padding: 30px;
    }
  }

  .dialog_title {
    font-size: 18px;
    font-weight: 700;
    color: $c_black;
    margin-bottom: 10px;
  }

  .form_btn {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}

.react-tel-input {
  .country-list {
    padding: 0;

    .search-box {
      height: 40px;
      font-size: 14px;
      font-weight: 500;
      border-radius: 5px;
      line-height: normal;
      padding: 5px 10px;
      width: 100%;
      margin: 0;
      border: 1px solid $c_form_border;
    }

    li {
      &.search {
        padding: 10px 10px 5px 10px;
      }

      &.country {
        font-size: 14px;
        font-weight: 500;
        padding: 8px 10px 8px 40px;
      }

      .flag {
        left: 10px;
        top: 4px;
        margin: 0;
      }
    }
  }
}

.chat_box {
  max-width: 1000px;
  margin: 0 auto;

  .chat_left {
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    h2 {
      font-size: 16px;
      font-weight: 700;
      margin: 0;
      color: $c_white;
      padding: 10px 15px;
      background-color: $c_primary;
      min-height: 50px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
    }

    .chat_list {
      flex-grow: 1;
      padding: 0;
      margin: 0;
      list-style: none;
      max-height: 460px;
      overflow: auto;
      border: 1px solid rgba(0, 0, 0, 0.1);

      li {
        display: flex;
        align-items: flex-start;
        padding: 10px 15px;
        gap: 10px;
        cursor: pointer;
        transition: $transition;
        position: relative;

        &:hover {
          background-color: #f5f5f5;
        }

        &.active {
          background-color: rgba(32, 78, 51, 0.1);
        }

        &:not(:last-child) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        figure {
          height: 35px;
          width: 35px;
          min-width: 35px;
          border-radius: 50%;
          overflow: hidden;
          margin: 0;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        .flex {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          gap: 15px;
          margin: 0 0 2px;

          h3 {
            font-size: 14px;
            font-weight: 700;
            margin: 0;
            color: $c_black;
            white-space: normal;
            display: -webkit-box !important;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          span {
            font-size: 12px;
            font-weight: 500;
            margin: 0;
            color: $c_text_grey;
            white-space: nowrap;
          }
        }

        p {
          font-size: 12px;
          font-weight: 500;
          margin: 0;
          color: #555;
          white-space: normal;
          display: -webkit-box !important;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          padding-right: 30px;
        }

        .MuiBadge-root {
          font-size: 12px;
          font-weight: 500;
          position: absolute;
          right: 15px;
          bottom: 10px;
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $c_white;
          background-color: $c_primary;
          border-radius: 50%;
        }
      }
    }
  }

  .chat_right {
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 0;
    box-sizing: border-box;

    &_head {
      display: flex;
      align-items: center;
      min-height: 50px;
      gap: 5px;
      padding: 5px 15px;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      figure {
        height: 35px;
        width: 35px;
        min-width: 35px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      h2 {
        font-size: 16px;
        font-weight: 700;
        margin: 0;
      }
    }
  }

  .chat_body {
    padding: 15px;
    max-height: 400px;
    min-height: 400px;
    overflow: auto;
    box-sizing: border-box;
  }

  .chat_msg {
    display: flex;
    flex-direction: column;
    max-width: 70%;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &_head {
      display: flex;
      align-items: center;
      gap: 10px;

      figure {
        height: 25px;
        width: 25px;
        min-width: 25px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      h3 {
        font-size: 16px;
        font-weight: 700;
      }

      span {
        font-size: 12px;
        font-weight: 500;
        color: $c_text_grey;
        display: inline-flex;
      }
    }

    &_info {
      padding: 15px;
      background-color: #f5f5f5;
      border-radius: 0 15px 15px 15px;
      margin-top: 5px;

      p {
        font-size: 14px;
        font-weight: 500;
        color: $c_black;
        margin: 0;
        line-height: 1.5;
      }
    }

    &.sent {
      margin-left: auto;
      text-align: right;

      .chat_msg_head {
        justify-content: flex-end;
      }

      .chat_msg_info {
        border-radius: 15px 0 15px 15px;
      }
    }
  }

  .chat_foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    input {
      border-radius: 0;
      border: none;
      padding: 5px 15px;
      height: 60px;
      width: 100%;
    }

    button {
      height: 60px;
      width: 60px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: $c_primary;

      svg {
        font-size: 20px;
      }
    }
  }
}

.mn_hdng {
  color: $c_primary_text !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.upload_image_bnr,
.upload_image_preview2 {
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 40%;
  border-radius: 3px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  label {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
}

.upload_image_preview2 {
  img {
    width: 100%;
    height: 100%;
  }

  svg {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
}

.switch_flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.select_div {
  height: 50px;
}

.sub_headings {
  color: $c_secondary;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.Mui-disabled {
  input {
    -webkit-text-fill-color: black !important;
  }
}

.chip {
  background: rgb(204, 193, 35) !important;
}

.highlightOption {
  background: rgb(204, 193, 35) !important;

}

.multiSelectContainer li:hover {
  background: rgb(204, 193, 35) !important;

}

.uploadImg {
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;

  .upload_image_btn {
    position: absolute;
    color: #ccc123;
    left: 0;
    cursor: pointer;
    right: 0;
  }

  .MuiInputBase-root {
    background: #0f0f0f;
    position: relative;
    min-height: 49px;
    border-radius: 3px;

    .MuiInputBase-input {
      padding: 10px;
      border: 1px solid #ddd;
      opacity: 0;
      max-width: 130px;
      color: #fff;
      z-index: 1;
      cursor: pointer;

      &::after {
        display: none;
      }
    }
  }

  .css-11w6z9m-MuiInputBase-root-MuiInput-root:after {
    border: 0;
  }

}

.upld_csv {
  p {
    margin-left: 40px;
  }

  svg {
    position: absolute;
    left: 21px;
    top: 23px;
    color: #ccc123 !important;
    cursor: pointer;
    transform: rotate(177deg);
  }
}